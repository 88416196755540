<ng-container *transloco="let t; read: 'searchBarAdvanced.casesResultsTable'">
  <div class="flex flex-col h-full pt-6 gap-4 border-border border-t">
    <div class="flex flex-row items-center">
      <span class="text-medium-emphasis text-subtitle-2 pr-6"
        >{{ t('searchCriteriaLabel') }}
      </span>
      <mat-radio-group
        (change)="radioButtonChanged()"
        [(ngModel)]="criteriaSelectedValue"
        class="flex flex-row gap-6 px-2"
      >
        @for (criteria of criteriaSelections; track criteria) {
          <mat-radio-button
            [value]="criteria"
            color="primary"
            class="text-subtitle-2"
            labelPosition="after"
            disableRipple
            >{{ t(criteria) }}</mat-radio-button
          >
        }
      </mat-radio-group>
    </div>
    <mat-card appearance="outlined" class="h-full">
      @if (loading) {
        <ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
      } @else {
        @if (!casesResults) {
          <ng-container [ngTemplateOutlet]="noInitialResults"></ng-container>
        } @else {
          @if (casesResults?.length === 0) {
            <ng-container [ngTemplateOutlet]="noResults"></ng-container>
          } @else {
            <ng-container [ngTemplateOutlet]="tableResults"></ng-container>
          }
        }
      }
    </mat-card>
  </div>

  <ng-template #noResults>
    <gq-no-data
      class="h-full bg-secondary-variant"
      [title]="t('noResultsTitle')"
    >
    </gq-no-data>
  </ng-template>

  <ng-template #noInitialResults>
    <gq-no-data
      class="h-full bg-secondary-variant"
      [title]="t('noInitialResultsTitle')"
      [subTitle]="t('noInitialResultsSubTitle')"
    >
    </gq-no-data>
  </ng-template>
  <ng-template #tableResults>
    <!--
    Dynamically set spinner's class and dialog size based on result count by assigning a panelClass to MatDialogConfig. 
    Modify panel classes and sizes in gq module's styles.scss. 
    Applies to '#loadingTemplate' as well.
    -->
    <ag-grid-angular
      oncontextmenu="return false;"
      class="ag-theme-material h-full"
      [ngClass]="{
        'cases-results-table-with-pagination': casesResults?.length > 4
      }"
      [defaultColDef]="defaultColDef"
      [columnDefs]="columnDefs"
      [components]="components"
      [context]="gridContext"
      [localeText]="localeText$ | ngrxPush"
      (gridReady)="onGridReady($event)"
      (columnResized)="onColumnChange($event)"
      (columnMoved)="onColumnChange($event)"
      (sortChanged)="onColumnChange($event)"
      (filterChanged)="onFilterChanged($event)"
      (rowDoubleClicked)="onRowDoubleClicked($event)"
      (columnPinned)="onColumnChange($event)"
      rowSelection="single"
      [rowData]="casesResults"
      [postSortRows]="searchbarColUtilsService.postSortRows"
      [gridOptions]="
        casesResults?.length > 8 ? gridOptions : gridOptionsWithoutPagination
      "
      columnMenu="legacy"
    >
    </ag-grid-angular>
  </ng-template>
  <ng-template #loadingTemplate>
    <schaeffler-loading-spinner
      [ngClass]="{
        'cases-results-table-with-pagination': casesResults?.length > 4
      }"
    ></schaeffler-loading-spinner>
  </ng-template>
</ng-container>
